/// <reference path="./libs.es.js" />
/// <reference path="./conf.js" />
/// <reference path="./mobile.es.js" />
'use strict';

/**
 * @typedef HomenItems APIから取得した加工済み方面データ
 * @type {Object}
 * @property {string} homen_id
 * @property {string} title
 * @property {string} homengcd
 * @property {string} homencd
 * @property {string} chikucd
 * @property {string} chiku_title
 * @property {string} deparea
 * @property {string} depcity
 * @property {number} tab_id
 * @property {number} count
 * @property {number} disppattern
 */
/**
 * @typedef STAT 状態管理変数
 * @type {Object}
 * @property {boolean} force
 * @property {number} init_deparea
 * @property {number} tab_id

 * @property {string} jp_homen
 * @property {number} jp_deparea
 * @property {string} jp_date
 * @property {number} jp_term
 * @property {string} jp_freeword

 * @property {string} lodge_homen
 * @property {number} lodge_deparea
 * @property {string} lodge_date
 * @property {number} lodge_term
 * @property {string} lodge_freeword

 * @property {string} world_homen
 * @property {number} world_deparea
 * @property {string} world_date
 * @property {number} world_term
 * @property {string} world_freeword
 * 
 */

/**
 * @typedef SearchParam 検索パラメータオブジェクト
 * @type {Object}
 * @property {number} nextPage
 * @property {number} listDisp 0:ツアー 1:バスハイク 2:宿泊
 * @property {string} freeword
 * @property {number} soat
 * @property {string} depArea
 * @property {string} homen
 * @property {string} area
 * @property {string} city
 * @property {number} term
 * @property {number} searchPattern 国内:0・海外:1
 */

/**
 * @typedef SearchItem
 * @type {Object}
 * @property {string} tourCd 商品コード
 * @property {string} tourCdHyphen ハイフン入り商品コード
 * @property {string} title
 * @property {string} subtitle
 * @property {string} depName
 * @property {string} homen
 * @property {number} term
 * @property {string} imagePath
 * @property {string} point
 * @property {string} salesDay
 * @property {string} price
 * @property {boolean} newFlag
 * @property {boolean} ossumeFlag
 * @property {boolean} shunFlag
 * @property {boolean} priceFlag
 * @property {boolean} kodawariFlag
 * @property {boolean} dpFlag
 * @property {string[]} iconList
 */

/**
 * @typedef SearchItems
 * @type {Object}
 * @property {SearchItem} _ITEM_CODE_?
 */

/**
 * @typedef SearchRawApi
 * @type {Object}
 * @property {number} total
 * @property {number} pageCount
 * @property {boolean} isNext
 * @property {number} nowPage
 * @property {SearchItems} items
 * @property {string} error
 */

/**
 * @typedef SearchApi
 * @type {Object}
 * @property {number} total
 * @property {number} pageCount
 * @property {boolean} isNext
 * @property {number} nowPage
 * @property {SearchItem[]} items
 * @property {string} error
 */

(function($)
{
	//-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
	//jQuery拡張
	//-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
	$.extend(
	{
		/**
		 * 末尾のeventを先頭に登録する関数
		 */
		eventFirst : function eventFirst( target, event = 'click' )
		{
			/**
			 * イベントを先頭にもってくる内部関数
			 */
			function eventSkip( elem, event )
			{
				let events;
				if( $.type(event) === "string" )
					events = event.split(' '); //スペース区切りを配列化
				else
					events = event;
				if( !$.isArray( events ) ) return target; //EXIT

				//イベントが複数指定でも対応
				const data = $._data( elem );
				_.each(events, function(evt)
				{
					const handlers = _.get( data, `events.${evt}` );
					if( $.isArray( handlers ) )
						handlers.unshift( handlers.pop() );
				});
			}

			//DOMオブジェクトならば
			if( $.isElement(target) )
			{
				eventSkip(target, event);
			}
			else if( target instanceof jQuery )
			{
				//jQueryオブジェクトならばeachで回す
				$.each( target, (idx, el) => {
					eventSkip(el, event);
				});
			}

			return target;
		},
		/**
		 * DOMオブジェクトか判定する
		 * @param {Object} obj
		 */
		isElement : function isElement( obj )
		{
			try
			{
				//Using W3 DOM2 (works for FF, Opera and Chrom)
				return obj instanceof HTMLElement;
			}
			catch ( exp )
			{
				//Browsers not supporting W3 DOM2 don't have HTMLElement and
				//an exception is thrown and we end up here. Testing some
				//properties that all elements have. (works on IE7)
				return ( typeof obj === "object" ) &&
					( obj.nodeType === 1 ) && ( typeof obj.style === "object" ) &&
					( typeof obj.ownerDocument === "object" );
			}
		},
	});

	/**
	 * イベント実行順序を先頭もっていく
	 * @description jQueryプラグイン
	 */
	$.fn.eventFirst = function eventFirst(...args)
	{
		switch (args.length)
		{
			case 1: //イベント名のみ
				$.eventFirst(this, args[0]);
				break;
			case 2: //イベント名, function
			case 3: //イベント名, セレクタ, function || object, セレクタ, object
				if( $.type(args[0]) === "string" )
				{
					//第一引数が文字列ならば
					this.on.apply(this, args).eventFirst(args[0]);
				}
				else
				{
					//第一引数がオブジェクトならばイベントの複数設定
					let keys = Object.keys(args[0]);
					this.on.apply(this, args).eventFirst(keys);
				}
				break;
			case 4: //イベント名, セレクタ, object, function
				this.on.apply(this, args).eventFirst(args[0]);
				break;
			default:
				break;
		}
		return this;
	};

	//無反応設定にしてreadonlyクラスを付与
	$.fn.disableSelection = function ()
	{
		return this
			.attr( 'unselectable', 'on' )
			.css( 'user-select', 'none' )
			.css( '-moz-user-select', 'none' )
			.css( '-khtml-user-select', 'none' )
			.css( '-webkit-user-select', 'none' )
			.addClass('readonly')
			.on( 'selectstart', false )
			.on( 'contextmenu', false )
			.on( 'keydown', false )
			.on( 'mousedown', false );
	};

	//無反応設定を除去してreadonlyクラスを除去
	$.fn.enableSelection = function ()
	{
		return this
			.attr( 'unselectable', '' )
			.css( 'user-select', '' )
			.css( '-moz-user-select', '' )
			.css( '-khtml-user-select', '' )
			.css( '-webkit-user-select', '' )
			.removeClass('readonly')
			.off( 'selectstart', false )
			.off( 'contextmenu', false )
			.off( 'keydown', false )
			.off( 'mousedown', false );
	};

})(jQuery);

/**
 * アプリケーション制御モジュール
 * @description libsモジュールとセットで動作致します
 */
const Ctrl = (
/** 即時実行 */
function(global)
{
	/**
	 * @type {STAT} STAT
	 */
	const STAT = {};

	//――――――――――――――――――――――――――
	// 管轄外のjs関数をオーバーライド
	//――――――――――――――――――――――――――
	if( global.changeMap )
	{
		/**
		 * 管轄外のマップの切替関数をオーバーライド
		 */
		const _tmp$changeMap = global.changeMap;

		/**
		 * 不用意な地図切替を無くすためのキャッシュ変数
		 */
		let _tmp$map = '';
		global.changeMap = function changeMap(_area_classname='')
			{
				//console.log('changeMap', _area_classname);
				/**
				 * 階層情報を取得 地図についての情報なので
				 */
				const info = inner.searchObject2(CONFIG.database, _area_classname);

				if( !_area_classname ) return info; //EXIT

				info.isChildren = $(`.search-map.${_area_classname}`).length > 0;

				//地図に子がない場合、親の地図を表示
				if( info.isChildren === false )
				{
					// _area_classname = _(info.sequence).takeRight(2).first();
					_area_classname = _.chain( info.sequence )
										.cloneDeep()
										.reverse()
										.drop()
										.find( classname =>
										{
											return $(`.search-map.${classname}`).length > 0;
										})
										.value()
										;
				}
				else
				{
					const isVisible = $(`.search-map li.${_area_classname} a`).isVisible();
				}

				if( _tmp$map == _area_classname ) return info; //EXIT

				/**
				 * バックアップした関数を実行
				 */
				_tmp$changeMap( _area_classname );
				_tmp$map = _area_classname;

				/**
				 * 国内⇔海外切替時、world|japanを経由しないと色がバグる問題対応
				 */
				(()=>
				{
					const map_mode = global.current_map = global.map_type = _.first( info.sequence ) || 'japan';
					if ( map_mode == 'world' )
					{
						$$( '#section-search-map .tab-button li:first-child a' ).removeClass( 'current' );
						$$( '#section-search-map .tab-button li:last-child a' ).addClass( 'current' );
					}
					else if ( map_mode == 'japan' )
					{
						$$( '#section-search-map .tab-button li:last-child a' ).removeClass( 'current' );
						$$( '#section-search-map .tab-button li:first-child a' ).addClass( 'current' );
					}
				})();

				return info;
			};
	}
	else
	{
		/**
		 * 存在しないなら落ちないようにしておく
		 */
		global.changeMap = function(_area_classname='')
		{
			const info = inner.searchObject2(CONFIG.database, _area_classname);
			return info;
		};
	}

	/**
	 * 状態復元・保存オブジェクト
	 * @description cookieやweb storageといった環境依存を封じ込めよう
	 */
	const storage = {
		/**
		 * 状態をストレージから復元
		 * @returns {STAT}
		 */
		recovery : function ()
		{
			try {
				return JSON.parse( sessionStorage.getItem('nisitetsu.stat') ) || {};
			} catch (err) {
				//console.log(err);
				return {};
			}
		},
		/**
		 * 状態をストレージに保存
		 * @param {STAT} _stat
		 */
		save : function (_stat)
		{
			return sessionStorage.setItem('nisitetsu.stat', JSON.stringify(_stat));
		},
	};

	/**
	 * 内部関数モジュール
	 */
	const inner = {
		/**
		 * ラベル名から出発地ID取得
		 * @description 「発」とか余分な文字列があってもマッチングします
		 */
		getDepareaFromLabel : function getDepareaFromLabel( label = '九州発' )
		{
			if( label.indexOf('九州') === 0 )
				return CONST.dep.九州;
			else if( label.indexOf('東京') === 0 )
				return CONST.dep.東京;
			else if( label.indexOf('関西') === 0 )
				return CONST.dep.関西;
			else
				return CONST.dep.九州; //九州デフォルト
		},
		/**
		 * なにもさせないイベント
		 */
		eventStop: function eventStop(evt)
		{
			return $.eventStop(evt);
		},
		/**
		 * docodoco.jpを使い、IPから出発地IDを割り出す
		 * @returns {number} 0|1|2
		 */
		getDepAreaByIP : function getDepAreaByIP()
		{
			try {
				//SEQ40688 <body class="disabledocodoco">はDOCODOCOJPを使用しない
				if( $('body').hasClass('disabledocodoco') ) throw new Error('docodoco disabled.');

				if(SURFPOINT.getPrefCode() <= 22)
					return CONST.dep.東京;
				else if (SURFPOINT.getPrefCode() <= 39)
					return CONST.dep.関西;
				else
					return CONST.dep.九州; //九州デフォルト
			} catch (err) {
				//console.error(err);
				return CONST.dep.九州; //九州デフォルト
			}
		},
		/**
		 * 活性化されているtab_idを取得する
		 */
		getCurrentTabid: function getCurrentTabid()
		{
			return +$('.form-content.current').data('tab-id');
		},
		/**
		 * 各ページで設定されているであろう初期表示方面取得
		 */
		getDefaultHomenClassname : function getDefaultHomenClassname(_def = 'japan')
		{
			return CONFIG.city || CONFIG.area || CONFIG.homen || _def;
		},
		/**
		 * 初期表示方面が設定されているか判定
		 */
		isSetDefaultHomenClassname : function isSetDefaultHomenClassname(_def = 'japan')
		{
			return !!inner.getDefaultHomenClassname(false);
		},
		/**
		 * オブジェクトからキーを検索し、その深度を返す
		 * @description 末端階層はstring
		 * @param {Object} _target 検索対象オブジェクト
		 * @param {String} _search 検索キー
		 */
		searchObject: function searchObject(_target, _search)
		{
			const obj = {
				target: _target,
				search: _search,
				depth: 0,
				isChildren: false,
				path: [],
			};

			/**
			 * 深さを調べる内部関数
			 */
			function _deepSearch( _item )
				{
					//検索ターゲットがfalseyな場合
					if( !_item )
					{
						return false;
					}

					if( _.has( _item, obj.search ) )
					{
						obj.path.unshift( obj.search );
						if( _.isString( _item[ obj.search ] ) )
							obj.isChildren = false;
						else
							obj.isChildren = true;
						return true;
					}
					else
					{
						for ( let key in _item )
						{
							let item = _item[ key ];
							//console.log('search:', _search, 'item:', item ,_.isObject( item ));
							if ( _.isObject( item ) )
							{
								if( _deepSearch( item ) )
								{
									obj.depth++;
									obj.path.unshift( key );
									return true; //ヒットしたならば
								} 
							}
						}
						return false;
					}
				}

			const ishit = _deepSearch(obj.target);
			if( !ishit ) obj.depth = -1;

			return {
				depth : obj.depth,
				isChildren: obj.isChildren,
				sequence : obj.path,
				path : obj.path.join('.'),
			};
		},

		/**
		 * オブジェクトからキーを検索し、その深度を返す
		 * @description 末端階層はstring
		 * @param {Object} _target 検索対象オブジェクト
		 * @param {String} _search 検索キー
		 */
		searchObject2: function searchObject2(_target, _search)
		{
			//変化オブジェ
			const obj = {
				code: null,
				target: _target,
				search: _search,
				depth: 0,
				isChildren: false,
				path: [],
			};

			//互換性を保つためのマジックコード
			if(_search == 'japan' || _search == 'world')
			{
				return {
					code : _search,
					depth : 0,
					isChildren: true,
					sequence : [_search],
					path : _search,
				};
			}

			/**
			 * 深さを調べる内部関数
			 */
			function _hasClassname( _item )
			{
				//検索ターゲットがfalseyな場合
				if( !_item )
					return false;

				//console.info( _.get( _item, 'classname' ), obj.search);

				//検索してる値があるか調べる
				if( _.get( _item, 'classname' ) == obj.search )
				{
					//あった場合
					obj.code = _.get(_item, 'code');
					if( _.chain( _item ).get( 'value' ).size().value() )
						obj.isChildren = true;
					else
						obj.isChildren = false;
					return true;
				}
				else
				{
					return false;
				}
			}

			function _find(items)
			{
				return _.find(items, function(item)
				{
					//console.warn(item);
					if( _hasClassname( item ) )
					{
						//console.log('_hasClassname', item.classname);
						obj.depth++;
						obj.path.unshift( item.classname );
						return true; //ヒットしたならば
					}
					else if( _.has(item,'value') )
					{
						//子がある場合 再帰
						if(_find( item.value ))
						{
							//console.log('再帰', obj.depth, item.classname);
							//console.info(item);
							obj.depth++;
							obj.path.unshift( item.classname );
							return true;
						}
						return false;
					}
				});
			}

			const ishit = _find(obj.target);

			if( !ishit ) obj.depth = -1;
			if( ishit )
			{
				//console.log(obj.code);
				//先頭が数字なら国内
				if( /^\d/.test( obj.code ) )
				{
					obj.path.unshift( 'japan' );
					//国内は第２階層まで
					if( obj.depth > 1 ) obj.isChildren = false;
				}
				else
				{
					obj.path.unshift( 'world' );
				}
			} 

			return {
				code : obj.code,
				depth : obj.depth,
				isChildren: obj.isChildren,
				sequence : obj.path,
				path : obj.path.join('.'),
			};
		},

		/**
		 * areaAJsonとCONFIG.masterのデータを相互補完
		 */
		mergeDatabase: function mergeDatabase()
		{
			if( typeof areaAJson !== 'undefined' )
			{
				_.merge(CONFIG, {
					database : JSON.parse( areaAJson ),
				});
				areaAJson = null; //解放

				//データ結合
				_.each(CONFIG.database, function(homen)
				{
					let homen_id = homen.code;
					//homen_id = '指定なし' == homen_id ? '' : homen_id;
					const SearchObj = { homen_id : homen_id };

					//この階層で中国がでてきた場合は日本の中国地方の事
					if( '中国' === homen_id )
					{
						SearchObj.group = 'japan';
					}

					const masterInfo0 = _.find( CONFIG.master, SearchObj );
					if( masterInfo0 )
					{
						masterInfo0.code = homen.code;
						_.merge( homen, masterInfo0 );
					}

					_.each(homen.value, function(area)
					{
						let area_id = area.code;
						//area_id = '指定なし' == area_id ? `${homen_id}全域` : area_id;
						
						const SearchObj = { homen_id : area_id };
						//この階層で中国がでてきた場合はアジアの中国
						if( '中国' === area_id )
						{
							SearchObj.group = 'asia';
						}

						const masterInfo1 = _.find( CONFIG.master, SearchObj );
						if( masterInfo1 )
						{
							masterInfo1.code = area.code;
							_.merge( area, masterInfo1 );
						}

						_.each(area.value, function(city)
						{
							let city_id = city.code;
							//city_id = '指定なし' == city_id ? `${area_id}全域` : city_id;
							const masterInfo2 = _.find( CONFIG.master, {homen_id: city_id} );
							if( masterInfo2 )
							{
								masterInfo2.code = city.code;
								_.merge( city, masterInfo2 );
							}
						});
					});
				});
			}

			if( typeof areaHotelJson !== 'undefined' )
			{
				const hoteldata = JSON.parse( areaHotelJson );

				_.merge(CONFIG, {
					hoteldata : _.map(hoteldata, item =>
					{
						if( _.has(item, 'value') )
						{
							item.value = _.map( item.value, item =>
							{
								if( _.has(item, 'value') )
								{
									item.value = _.map( item.value, item =>
									{
										return item;
									});
								}
								return item;
							} );
						}
						return item;
					}),
				});
				areaHotelJson = null; //解放


			}
		},
		/**
		 * 整形した地図のDOMリストを返します
		 * @description メモリキャッシュ化しているので同じ結果を返します
		 */
		getMapDomList : _.memoize(function( _query )
		{
			if (!_query) _query = '.search-map li';

			return _.map( $$( _query ), (dom)=>
				{
					const $dom = $(dom);
					const homen_id = inner.formatHomen( $dom.text() );
					const classname = _(dom.className).split(' ').without('link').without('back').first() || '';
					const disppattern = +_.chain(CONFIG.master).find({classname:classname}).get('disppattern').value();
					return {
						homen_id : homen_id,
						label : $dom.text(),
						classname : classname,
						disppattern: disppattern,
						dom : dom,
					};
				});
		}),
		/**
		 * tab_idから国内海外フラグ求める
		 * @description 国内:1 | 海外:2 | 国内外:0
		 */
		getDispPattern : function (_tab_id = 1)
		{
			if( _tab_id == 1 || _tab_id == 2 )
				return CONST.api.国内;
			else if( _tab_id == 3 )
				return CONST.api.海外;
			else
				return CONST.api.国内外;
		},
		/**
		 * tab_idから国内海外フラグ求める
		 * @description 国内:1 | 海外:2 | 国内外:0
		 */
		getSearchPatternByTabId : function (_tab_id = 1)
		{
			if( +_tab_id === 3)
				return CONST.link.海外;
			else
				return CONST.link.国内;
		},
		/**
		 * tab_idからlistDisp（ツアー・バスハイク・宿泊フラグ）を取得
		 * @description 国内:1 | 海外:2 | 国内外:0
		 */
		getModeByTabId : function (_tab_id = 0)
		{
			if( +_tab_id === 2)
				return CONST.mode.宿泊;
			else
				return CONST.mode.ツアー;
		},
		/**
		 * searchPatternからtab_id求める
		 * @description 国内:1 | 海外:2 | 国内外:0
		 */
		getTabIdBySearchPattern : function (_searchPattern = CONST.link.国内)
		{
			if( +_searchPattern === CONST.link.海外)
				return CONST.tab.海外;
			else
				return CONST.tab.国内;
		},
		/**
		 * disppatternから国内海外フラグ求める
		 * @description 国内:1 | 海外:2 | 国内外:0
		 */
		getSearchPatternByDispPattern : function (_disppattern = CONST.api.国内)
		{
			if( +_disppattern === CONST.api.海外)
				return CONST.link.海外;
			else
				return CONST.link.国内;
		},
		/**
		 * tab_idから操作対象となるDOMコンテンツを取得する
		 */
		getTargetContentByTabid : function getTargetContentByTabid(_tab_id = CONST.tab.国内)
		{
			const tabElem = _.find( $$(`.form-content`), (elem)=>{
					const tab_id = +$(elem).data('tab-id');
					return tab_id === _tab_id;
				});
			//console.log('getTargetContentByTabid', tabElem);
			return $(tabElem);
		},
		/**
		 * 方面データ取得
		 * @description メモリキャッシュ化しているので同じ結果を返します
		 * @param {number} _deparea 出発地ID
		 * @param {number} _tab_id 対象のタブID
		 * @param {string} _homengcd 方面コード
		 */
		getHomenItems : (_deparea, _tab_id, _homengcd = '', _homencd = '') =>
		{
			const env = {
				deparea: _deparea,
				tab_id: _tab_id,
				homengcd: _homengcd,
				homencd : _homencd,
			};
			log('getHomenItems', _tab_id, 'env', env, );
			if( _tab_id == 2 )
				return new Promise( inner.getLodgeHomenItems.bind(env) );
			else
				return new Promise( inner.getTourHomenItems.bind(env) );
		},
		/**
		 * ツアー用方面データAPIを利用
		 * @param {PromiseLike<HomenItems>} _resolve
		 */
		getTourHomenItems : function getTourHomenItems(_resolve, _reject)
		{
			const env = this;
			const disppattern = inner.getDispPattern(env.tab_id);
			//console.log(env);

			//行き先情報APIコール、データ更新
			const apiParam = {
				jsonp: 1,
				depcity: env.deparea,
				disppattern: disppattern,
				homengcd: env.homengcd,
				homencd: env.homencd,
			};

			log('apiParam',apiParam);

			Ajax.load({
				url : CONFIG.url.api,
				type: 'GET',
				dataType: 'jsonp',
				cache: 1000 * 60 * 30,
				data: apiParam,
			})
			.then((_json)=>
			{
				//エラーチェック
				return _json;
			})
			.then((_json)=>
			{
				//console.warn(_json);
				//データ加工
				return _.chain(_json)
					.map(
						/** @param {HomenItems} item */
						(item)=>{
						/** 沖縄対応（）内の文字だけ抽出 */
						const title = _.last( item.title.match(/（(.+)）/) ) || item.title;
						item.title = inner.labelDisplay( title );
						item.homen_id = item.chikucd || item.homencd || item.homengcd;
						item.tab_id = env.tab_id;
						return item;
					})
					.value()
					;
			})
			.then(/** @param {HomenItems} item */
			(item) => {
				//console.log(item);
				return _resolve(item);
			})
			.fail((err)=>
			{
				//console.log(err);
				return _reject(err);
			});
		},
		/**
		 * 宿泊用方面データを利用
		 * @param {PromiseLike<HomenItems>} _resolve
		 */
		getLodgeHomenItems: function getLodgeHomenItems(_resolve, _reject)
		{
			const env = this;
			log('getLodgeHomenItems', env);
			const disppattern = inner.getDispPattern(env.tab_id);
			const homengcd = _.get( env, 'homengcd', '' ); //第一階層
			const homencd = _.get( env, 'homencd', '' ); //第二階層
			const is1st = homengcd === ''; //第一階層を処理するか判定
			const is2nd = !is1st && homencd === ''; //第二階層を処理するか判定
			const defHomen = inner.getDefaultHomenClassname();

			log('is1st', is1st);
			log('is2nd', is2nd);
			if( is1st )
			{
				//第１階層のデータをいったんリセット
				_.chain(CONFIG.master)
					.filter( {group : defHomen } )
					.each( item =>{
						_.set(item, 'count', 0);
					})
					.value();

				//codeプロパティの値を取り出します
				const codes = _.pluck( CONFIG.hoteldata, 'code' );
				log('codes', codes);
				const items = _.reduce( codes, (acc, code) => {
					const hitItem = _.find(CONFIG.master, {code: code} );
					if( hitItem )
					{
						_.merge(hitItem, {
							count: 1,
							tab_id: env.tab_id,
							disppattern: disppattern,
						});
						acc.push(hitItem)
					}
					return acc
				}, []);
				log('is1st items', items);
				return _resolve( items );
			}
			//第２階層処理
			else
			{
				//第１階層のgroup名を取得する
				const group1st = _.chain(CONFIG.master)
					.find( {group : defHomen, code : homengcd} )
					.get('classname', '')
					.value()
					;

				//第２階層のリストを取得(リセットも行う)
				const list2nd = _.chain(CONFIG.master)
					.filter( {group : group1st } )
					.each(item => {
						_.set(item, 'count', 0);
					})
					.value()
					;
				//console.log('list2nd',list2nd);

				//CONFIG.hoteldataデータ依存 codeだけ取得
				const codes = _.chain(CONFIG.hoteldata).find({code:`${homengcd}`}).get(`value`).pluck('code').value();
				//console.log('is2nd codes',codes);
				//カウントをセットする
				const items = _.reduce( codes, (acc, code) => {
					const hitItem = _.find(list2nd, {code: code} );
					if( hitItem )
					{
						_.merge(hitItem, {
							count: Number.MAX_SAFE_INTEGER,
							tab_id: env.tab_id,
							homencd: code,
							disppattern: disppattern,
						});
						acc.push(hitItem)
					}
					return acc
				}, []);
				//log('is2nd items',items);
				return _resolve(items);
			}
		},
		/**
		 * 地図の方面名から余分な記号文字列を除去するラベル用
		 * @param {string} _text
		 * @returns {string}
		 */
		labelDisplay : function labelDisplay(_text = '')
		{
			return _text
				.trim()
				.replaceAll('、','・')
				.replaceAll('（','')
				.replaceAll('）','')
				;
		},
		/**
		 * 地図の方面名から余分な記号文字列を除去する
		 * @param {string} _text
		 * @returns {string}
		 */
		formatHomen : function formatHomen(_text = '')
		{
			return _text
				.trim()
				.replaceAll('・','')
				.replaceAll('、','')
				.replaceAll('（','')
				.replaceAll('）','')
				;
		},
		/**
		 * codeから方面グループコードを取得
		 */
		getHomengcd: function getHomengcd( code = '' )
		{
			return code.substr(0,1);
		},
		/**
		 * codeから方面コードを取得
		 */
		getHomencd: function getHomencd( code = '' )
		{
			if( code.length >= 2 )
				return code.substr(0,2);
			else
				return '';
		},
		/**
		 * codeから地区コードを取得
		 */
		getChikucd: function getChikucd( code = '' )
		{
			if( code.length >= 3 )
				return code.substr(0,3);
			else
				return '';
		},
		/**
		 * データセット関数
		 * @param {HomenItems} _apidata
		 */
		setData :  function setData(_apidata)
		{
			log('setData', _apidata);
			const tab_id = _.get(this, 'tab_id');
			if( !tab_id ) throw new Error('tab_id is no bind');

			_.each(_apidata, data =>
			{
				//log('API DATA',data);
				//APIデータの中にヒットする地図情報があるならば
				/** @type {HomenItems} */
				const masterInfo = _.find(CONFIG.master, {
					homen_id : data.homen_id,
					disppattern: data.disppattern,
				});

				/**
				 * 地図情報に値をセット、補完していく
				 */
				if(masterInfo)
				{
					masterInfo.count = data.count;
					masterInfo.deparea = data.depcity; //※depcityだよ。注意
					masterInfo.homengcd = data.homengcd;
					masterInfo.homencd = data.homencd;
					masterInfo.city = data.chikucd;
					masterInfo.tab_id = tab_id;

					//グループもわかるところは書き換える
					_.chain(CONFIG.master)
						.filter({
							group : masterInfo.classname
						})
						.each(_groupInfo =>
						{
							if( data.homengcd )
								_groupInfo.homengcd = data.homengcd;

							if( data.homencd )
								_groupInfo.homencd = data.homencd;
						})
						.value()
						;

					//３階層目にある都市がCONFIG.databaseに存在しない場合があるので補完
					if( masterInfo.homencd )
					{
						const path = `${masterInfo.homengcd}.value.${masterInfo.homencd}`;
						const dbData = _.get( CONFIG.database, path );
						log('the 3rd path ',path, dbData);
						if( !dbData )
						{
							const setObj = {
								classname : masterInfo.classname,
								//count : masterInfo.count,
								code : masterInfo.homencd,
								disppattern : masterInfo.disppattern,
								group : masterInfo.classname,
								homen_id : masterInfo.homen_id,
								label : masterInfo.label,
								name : masterInfo.label,
								homengcd : inner.getHomengcd( masterInfo.homencd ),
								homencd : masterInfo.homencd,
							};
							_.set( CONFIG.database, path, setObj);
						}
					}
				}
			});

			return _apidata;
		},
		/**
		 * 目的のクラス名までデータを取得する関数
		 */
		fetchHomen : function fetchHomen(_deparea, _tab_id, _classname)
		{
			//console.warn(_deparea, _tab_id, _classname);
			return new Promise(function(_resolve, _reject)
			{
				const masterInfo = _.find(CONFIG.master, { classname : _classname });
				const treeInfo = inner.searchObject2(CONFIG.database, _classname );
				const setDataBindFunc = inner.setData.bind( { tab_id: _tab_id} );
				const obj = {
					masterInfo: masterInfo,
					treeInfo: treeInfo,
				};
				//console.warn('obj', obj);

				const _homengcd = _.get(masterInfo, 'homengcd', '');
				const _homencd = _.get(masterInfo, 'homencd', '');
				const _city = _.get(masterInfo, 'city', '');

				log('treeInfo.depth', treeInfo);

				switch (treeInfo.depth)
				{
					case 0:
						inner.getHomenItems( _deparea, _tab_id, '', '')
							.then( setDataBindFunc )
							.then( function(){
								return _resolve(obj);
							})
							;
						break;
					case 1:
						//if( !!_homengcd ) return _resolve(obj);
						//console.warn(_deparea, _tab_id, '', '');
						inner.getHomenItems( _deparea, _tab_id, '', '')
							.then( setDataBindFunc )
							.then( function(_apidata){
								/** @type {HomenItems} */
								const _info = _.find(CONFIG.master, { classname : treeInfo.sequence[1] });
								//console.warn('case 1',_info);
								return inner.getHomenItems(_deparea, _tab_id, inner.getHomengcd(_info.code), '');
							})
							.then( setDataBindFunc )
							.then( function(){
								return _resolve(obj);
							})
							;
						break;
					case 2:
						inner.getHomenItems(_deparea, _tab_id, '', '')
							.then( setDataBindFunc )
							.then( function(_apidata){
								/** @type {HomenItems} */
								const _info = _.find(CONFIG.master, { classname : treeInfo.sequence[1] });
								log('case 2 1',_info);
								return inner.getHomenItems(_deparea, _tab_id, inner.getHomengcd(_info.code), '');
							})
							.then( setDataBindFunc )
							.then( function(_apidata){
								const _info = _.find(CONFIG.master, { classname : treeInfo.sequence[2] });
								log('case 2 2',_info);
								return inner.getHomenItems(_deparea, _tab_id, inner.getHomengcd(_info.code), inner.getHomencd(_info.code));
							})
							.then( setDataBindFunc )
							.then( function(_apidata){
								log('case 2 3',_apidata);
								return _resolve(obj);
							})
							;
						break;
					case 3:
						//if( !!_homengcd && !!_homencd && !!_isCity ) return _resolve(obj);
						inner.getHomenItems(_deparea, _tab_id, '', '')
							.then( setDataBindFunc )
							.then( function(_apidata){
								/** @type {HomenItems} */
								const _info = _.find(CONFIG.master, { classname : treeInfo.sequence[1] });
								//console.warn('case 3',_info);
								return inner.getHomenItems(_deparea, _tab_id, inner.getHomengcd(_info.code), '');
							})
							.then( setDataBindFunc )
							.then( function(_apidata){
								const _info = _.find(CONFIG.master, { classname : treeInfo.sequence[2] });
								//console.warn('case 3',_info);
								return inner.getHomenItems(_deparea, _tab_id, inner.getHomengcd(_info.code), '');
							})
							.then( setDataBindFunc )
							.then( function(_apidata){
								const _info = _.find(CONFIG.master, { classname : treeInfo.sequence[3] });
								//console.warn('case 3',_info);
								return inner.getHomenItems(_deparea, _tab_id, inner.getHomengcd(_info.code), inner.getHomencd(_info.code));
							})
							.then( setDataBindFunc )
							.then( function(){
								return _resolve(obj);
							})
							;
						break;
					default:
						return _resolve(obj);
				}

			});
		},
		/**
		 * 検索ページへ遷移する
		 * @param {SearchParam|string} _param
		 */
		gotoSearch : function gotoSearch( _param )
		{
			if( _.isString( _param ) )
			{
				//location.href = _param;
				window.open(_param, '_blank');
			}
			else if( _.isObject(_param) )
			{
				const paramQuery = $.param(_param);
				const link = `${CONFIG.url.search}?${paramQuery}`;
				//console.info(link);
				//location.href = link;
				window.open(link, '_blank');
			}
		},
		/**
		 * 地図補足情報生成
		 * @description 使い方：console.log( JSON.stringify( Ctrl.inner._makeLinkingConfig() ) );
		 */
		_makeLinkingConfig: function()
		{
			const list = inner.getMapDomList();
			const maps = _.chain(list)
				.filter('homen_id')
				.map((item)=>
				{
					const $group = $(item.dom).closest('.search-map');
					const group = _( $group.get(0).className ).split(' ').without('search-map').first();

					const obj = {
						disppattern : item.disppattern,
						classname : item.classname,
						homen_id : item.homen_id,
						label : inner.labelDisplay( $(item.dom).text() ),
						group : group,
					};
					return obj;
				})
				.value()
				;
			return maps;
		},
	}

	/** *********************************************************
	 * 画面書き換え実装モジュール
	 * **********************************************************
	 * @description jQueryでDOMを書き換える処理はこのモジュールに
	 */
	const view = {
		/**
		 * ラベル名から出発地（input）のvalueをセット
		 */
		setConstDepArea : function setConstDepArea()
		{
			$$('.whence-radio').each(function(idx, dom)
			{
				$(dom).find('label.radio').each(function(idx, dom)
				{
					const $dom = $(dom);
					const label = $dom.text();
					const deparea = inner.getDepareaFromLabel( label );
					$('input', $dom).val( deparea ).attr('name', 'depArea');
				});
			});
		},
		/**
		 * タブ変更
		 */
		updateTab : function updateTab(_tab_id = 1)
		{
			log('updateTab',_tab_id);
			const $self = $$(`li[data-tab-id="${_tab_id}"]`, '.search-for-form .tab-menu');
			const tab_group = $self.attr('data-tab-group');

			if ($$(`.tab-content[data-tab-group="${tab_group}"][data-tab-id="${_tab_id}"]`).length) {
				$$('.search-for-form .tab-menu li').removeClass('current');
				$($self).addClass('current');
				$$(`.tab-content[data-tab-group="${tab_group}"]`).hide().removeClass('current');
				$$(`.tab-content[data-tab-group="${tab_group}"][data-tab-id="${_tab_id}"]`).show().addClass('current');
			}
		},
		/**
		 * フェードイン表示ラッパー関数
		 * @param {JQuery} $_elem
		 */
		 visible : function visible( $_elem, _opt1, _opt2 )
		 {
			const opt1 = _.merge( {opacity: '1.0'}, _opt1 );
			const opt2 = _.merge( {
				duration:'fast',
				begin: function(self){
					$(self).css( 'visibility', 'visible' );
				},
			}, _opt2 );

			 $_elem.velocity(opt1, opt2);
			 return $_elem;
		 },
		/**
		 * フェードアウト非表示ラッパー関数
		 * @param {JQuery} $_elem
		 */
		 unvisible : function unvisible( $_elem, _opt1, _opt2 )
		 {
			const opt1 = _.merge( {opacity: '0.0'}, _opt1 );
			const opt2 = _.merge( {
				duration:'fast',
				complete: function (self){
					$(self).css( 'visibility', 'hidden' );
				},
			}, _opt2 );
			
			 $_elem.velocity(opt1, opt2);
			 return $_elem;
		 },
		/**
		 * 有効化ラッパー関数
		 * @param {JQuery} $_elem
		 */
		 enable : function enable( $_elem )
		 {
			 $_elem.enableSelection();
			 return $_elem;
		 },
		/**
		 * 無効化ラッパー関数
		 * @param {JQuery} $_elem
		 */
		 disable : function disable( $_elem )
		 {
			 $_elem.disableSelection();
			 return $_elem;
		 },
		/**
		 * 地図切替
		 * @description 地図切替関数注意 処理中フラグが邪魔して連続してコールできない
		 */
		updateMap : _.throttle(function updateMap(_area_classname)
		{
			log('updateMap:', _area_classname);

			/**
			 * 地図切替実行
			 * @type {Object} info
			 */
			const info = global.changeMap.call(global, _area_classname);

			//得られた情報
			//console.log('info:', info);

			/**
			 * タブ情報
			 */
			const tab_id = STAT.tab_id || 1;

			/**
			 * 出発地取得
			 */
			const deparea = (()=>
			{
				if( tab_id === 1 )
					return nvl( STAT.jp_deparea, STAT.init_deparea, CONST.dep.九州 );
				else if( tab_id === 2 )
					return CONST.dep.東京;
				else
					return nvl( STAT.world_deparea, STAT.init_deparea, CONST.dep.九州 );
			})();

			/**
			 * 表示する地図クラス名
			 */
			const area_classname = (()=>
			{
				//指定がない場合、現在表示している地図情報をセット
				if( !_area_classname )
				{
					if( tab_id === CONST.tab.国内 )
						return STAT.jp_homen || inner.getDefaultHomenClassname();
					else if( tab_id === CONST.tab.宿泊 )
						return STAT.lodge_homen || inner.getDefaultHomenClassname();
					else
						return STAT.world_homen || inner.getDefaultHomenClassname('world');
				}
				else
					return _area_classname;
			})();

			//クラス名から方面コードを取得する
			//console.log( 'area_classname:', area_classname );

			//
			const masterInfo = _.find(CONFIG.master, {
				classname: area_classname
			});
			//console.log( 'masterInfo:', masterInfo );

			const $alink = $('a', `.search-map li.${area_classname}`);
			const homengcd = _.get(masterInfo, 'homengcd', '');
			const homencd =  _.get(masterInfo, 'homencd', '');

			//子情報がある、または書換命令があるならば、データ取得して画面書換
			if( nvl( info.isChildren, _.get(this,'sabun.jp_deparea'), _.get(this,'sabun.world_deparea' ) ) )
			{
				//console.log('子情報がある、または書換命令がある');
				const updateHomenCombo = view.updateHomenCombo.bind(null, tab_id, area_classname);

				inner.getHomenItems( deparea, tab_id, homengcd, homencd )
					.then( inner.setData.bind( { tab_id: tab_id} ) )
					.then( function(){
						return view.updateHomenMap( area_classname );
					})
					.then( updateHomenCombo )
					;
			}
			else
			{
				view.updateHomenMap( area_classname );
			}
			return _area_classname;
		}, 160, {leading: false, trailing: true }),
		/**
		 * 出発地切替：※thisに対して処理するのでthisにjQueryセレクタを束縛してください
		 */
		updateDepArea : function updateDepArea( _deparea = CONST.dep.九州 )
		{
			//console.title( 'updateDepArea', _deparea );
			//thisがjQueryオブジェクトで束縛されていなければエラー
			if( !(this instanceof jQuery) ) throw new Error('this is non jQuery object');
			const $forms = $(this);

			//出発地を代入(型をnumberにキャストしておく)
			const deparea = +_deparea;

			//ラジオボックス描画制御
			$forms.each(function(idx, elem)
			{
				const $input = $( elem ).find(`input[value="${deparea}"]`);

				//いったんcheckedクラスを除去
				$( elem ).find('label').removeClass('checked');

				//この回りくどい書き方は”for fack IE対策”
				$input.attr('checked', 'checked')
					.prop('checked',true)
					.trigger('change')
					.closest('label')
					.addClass('checked');
			});
		},
		/**
		 * @param {string} _homen 更新したい地図方面
		 */
		updateHomenMap: _.throttle(function updateHomenMap( _homen )
		{
			const targetList = _.filter( CONFIG.master, {group: _homen} );
			//console.log('*******', _homen, targetList);

			const mapDomList = inner.getMapDomList( `div.search-map.${_homen} li` );

			_.chain( mapDomList )
				.each((item)=>{
					const $li = $(item.dom);
					const $alink = $('a', $li);
					const masterInfo = _.find( targetList, {classname: item.classname } )
					const count = _.get( masterInfo, 'count', 0 );

					 //console.warn('masterInfo',item.classname,masterInfo);

					if( count > 0 )
					{
						//console.log('fadeIn',$alink);
						$alink.velocity('fadeIn', { duration: 'normal' } );

						(function(){
							const $svg = $(`#svg-${_homen}`);
							if( $svg.length )
							{
								const svg = _.get($svg.get(0), 'contentDocument');
								$(`#${item.classname}`, svg).find('polygon, path, polyline').off(`click`, inner.eventStop );
							}
						})();

						//方面別ページの地図のクリック時
						$(`#${item.classname}`).on(`click`, evt => {
							$.eventStop(evt);
							const dom = $alink.get(0);
							if( _.size(dom) ) dom.click(); //jQueryのtriggerでaタグのhrefへ遷移させる方法 domに直接アクセス
						});
					}
					else if( $li.hasClass('back') )
					{
						//戻るボタンの場合
						$alink.velocity('fadeIn', {
							begin: function(_self)
							{
								const backname = item.classname;
								$(_self)
									.removeAttr('onclick') //domにかかれているonclickを無効化
									.eventFirst('click', function(evt)
									{
										const info = inner.searchObject2(CONFIG.database, backname);
										const isLodge = (CONST.tab.宿泊 === STAT.tab_id);
										const isJP = _.first( info.sequence ) == 'japan';
										let stat = {};
										if( isLodge )
											stat = {
												lodge_homen: backname,
											};
										else if( isJP )
											stat = {
												jp_homen: backname,
											};
										else
											stat = {
												world_homen: backname,
											};
										render(stat);
										return $.eventStop(evt);
									})
									;
							}
						});
					}
					else
					{
						//非表示処理 カウント情報がなければ隠す
						$alink.velocity('fadeOut',{ duration:'fast' });

						//トップページの地図のボタン無効化
						(function(){
							const $svg = $(`#svg-${_homen}`);
							if( $svg.length )
							{
								const svg = _.get($svg.get(0), 'contentDocument');
								$(`#${item.classname}`, svg).find('polygon, path, polyline').eventFirst(`click`, inner.eventStop );
							}
						})();
						//方面別ページの地図のボタン無効化
						$(`#${item.classname}`).off(`click`);
					}
				})
				.value()
				;
		}, 160, {leading: false, trailing: true }),
		/**
		 * 行き先セレクトボックス更新
		 * @param {1|2|3} _tab_id 操作対象タブ
		 * @param {string} _homen 変更対象の方面class名
		 * @param {number} _depth 操作対象セレクトボックスの階層値
		 */
		updateHomenCombo: function updateHomenCombo(_tab_id, _homen)
		{
			//console.title( 'updateHomenCombo', _tab_id, _homen );

			/**
			 * 行き先（方面）の階層構造を取得
			 */
			const treeInfo = inner.searchObject2(CONFIG.database, _homen);
			//const tab_name = nvl( _.first( info.sequence ), '' );

			//console.log('方面:', _homen, 'info:', treeInfo );

			/**
			 * 操作対象のタブ
			 */
			const $tab = inner.getTargetContentByTabid(_tab_id);
			//console.info( $tab );
			/**
			 * 行き先に関するセレクトボックスの集合を取得
			 */
			const $selects = $tab.find('.homen-select').find('select');
			const $select1 = $selects.eq(0);
			const $select2 = $selects.eq(1);
			const $select3 = $selects.eq(2);

			//階層情報を順番に処理していく
			_.each(treeInfo.sequence, (prop, idx) =>
			{
				if( idx === 0 )
				{
					view.createSelectbox2( idx, treeInfo , $select1, '', prop, _tab_id);
				}
				else if( idx === 1 )
				{
					view.setSelectbox( $select1, prop);
					view.createSelectbox2( idx, treeInfo , $select2, '', prop, _tab_id);
				}
				else if( idx === 2 )
				{
					log(idx, treeInfo , $select3, '', prop, _tab_id);
					view.setSelectbox( $select2, prop);
					view.createSelectbox2( idx, treeInfo , $select3, '', prop, _tab_id);
				}
				else if( idx === 3 )
				{
					view.setSelectbox( $select3, prop);
				}
			});

			/**
			 * 空が選択されたならば
			 */
			const isBlank = (treeInfo.depth === -1);

			/**
			 * 末端ならば
			 */
			const isTail =  !treeInfo.isChildren;


			/**
			 * 表示非表示切替
			 */
			if( treeInfo.depth === 0 )
			{
				if( isBlank )
				{
			 		$select1.prop('disabled',false);
			 		$select2.prop('disabled',true);
			 		$select3.prop('disabled',true);
				}
				else
				{
			 		$select1.prop('disabled',false);
			 		$select2.prop('disabled',true);
			 		$select3.prop('disabled',true);
				}

				if( isTail )
				{
					view.unvisible( $select1 );
					view.unvisible( $select2 );
					view.unvisible( $select3 );
				}
				else
				{
					view.visible( $select1 );
					view.unvisible( $select2 );
					view.unvisible( $select3 );
				}
			}
			else if( treeInfo.depth === 1 )
			{
				if( isBlank )
				{
			 		$select1.prop('disabled',false);
			 		$select2.prop('disabled',true);
			 		$select3.prop('disabled',true);
				}
				else
				{
			 		$select1.prop('disabled',false);
			 		$select2.prop('disabled',false);
			 		$select3.prop('disabled',true);
				}

				if( isTail )
				{
					view.visible( $select1 );
					view.unvisible( $select2 );
					view.unvisible( $select3 );
				}
				else
				{
					view.visible( $select1 );
					view.visible( $select2 );
					view.unvisible( $select3 );
				}
			}
			else if( treeInfo.depth === 2 )
			{
				if( isBlank )
				{
			 		$select1.prop('disabled',false);
			 		$select2.prop('disabled',false);
			 		$select3.prop('disabled',true);
				}
				else
				{
			 		$select1.prop('disabled',false);
			 		$select2.prop('disabled',false);
			 		$select3.prop('disabled',false);
				}

				if( isTail )
				{
					view.visible( $select1 );
					view.visible( $select2 );
					view.unvisible( $select3 );
				}
				else
				{
					view.visible( $select1 );
					view.visible( $select2 );
					view.visible( $select3 );
				}
			}
			else if( treeInfo.depth === 3 )
			{
				$select1.prop('disabled',false);
				$select2.prop('disabled',false);
				$select3.prop('disabled',false);

				view.visible( $select1 );
				view.visible( $select2 );
				view.visible( $select3 );
			}
		},
		/**
		 *  セレクトボックスをリセット
		 * @description "選択してください"は残す
		 * @param {HTMLElement|JQuery} _dom
		 * @returns {JQuery}
		 */
		emptySelectbox: function emptySelectbox(_dom)
		{
			const $sel = $(_dom).addClass('placeholder');
			_.chain( $('option', $sel) )
				.filter('value') //valueが設定されているoptionのみ
				.each((elem)=>{
					$(elem).remove();
				})
				.value();

			return $sel;
		},
		/**
		 * セレクトタグ構築
		 * @param {HTMLElement|JQuery} _select DOM
		 * @param {string} _prop セレクト選択値
		 * @param {string} _parent 親階層
		 */
		createSelectbox: function createSelectbox(_select, _prop = '', _parent)
		{
			//console.log('createSelectbox', _prop, _parent);
			//初期化する
			const $select = view.emptySelectbox(_select).prop('disabled', false);
			//console.log( $select );
			_.chain(CONFIG.master)
				.filter( {group : _parent} )
				// .filter( (item)=>{
				// 	return !item.classname.match(/^all\-/i); //全域は排除
				// })
				.each( (item)=>{
					// const label = $('a',dom).data('label') || inner.labelDisplay( $(dom).text() );
					const isDisable = !_.get(item, 'count', 0);
					//console.warn(isDisable, _.get(item, 'count'));
					$(`<option value="${item.classname}">${item.label}</option>`)
						.prop('disabled', isDisable)
						.appendTo($select)
						;
				})
				.value();

			//選択
			$select.val(_prop);

			//空値はクラス付加
			if( '' == _prop )
				$select.addClass('placeholder');
			else
				$select.removeClass('placeholder');
			
			return $select;
		},
		/**
		 * セレクトタグ構築
		 * @param {HTMLElement|JQuery} _select DOM
		 * @param {string} _prop セレクト選択値
		 * @param {string} _parent 親階層
		 */
		createSelectbox2: function createSelectbox2(_idx, _treeInfo, _select, _prop = '', _parent, _tab_id)
		{
			//初期化する
			const $select = view.emptySelectbox(_select).prop('disabled', false);

			const homenList = (function()
			{
				if( _treeInfo.code == 'japan' || _treeInfo.code == 'world' )
				{
					return _.chain(CONFIG.database)
						.groupBy((item)=>
						{
							if( /^\d/.test( item.code ) )
								return 'japan';
							else
								return 'world';
						})
						.value()
						;
				}
				else
				{
					const codes = `${_treeInfo.code}`.split('');
					let path = '';
					if( codes.length == 1)
					{
						const code1 = codes[0];
						path = `${code1}.value`;
					}
					else if( codes.length == 2)
					{
						const code1 = codes[0];
						const code2 = codes[1];
						path = `${code1}.value.${code1}${code2}.value`;
					}
					return _.get( CONFIG.database, path );
				}
			})();

			//console.log(_treeInfo);
			log(_tab_id, _parent, homenList);
			_.chain(CONFIG.master)
				.filter( {group : _parent} )
				.each( item =>
				{
					const isDisable = !_.get(item, 'count', 0) || _.get(item, 'tab_id') != _tab_id;
					log( isDisable, item );
					$(`<option value="${item.classname}">${item.label}</option>`)
						.prop('disabled', isDisable)
						.appendTo($select)
						;
				})
				.value();

			//選択
			$select.val(_prop);

			//空値はクラス付加
			if( '' == _prop )
				$select.addClass('placeholder');
			else
				$select.removeClass('placeholder');
			
			return $select;
		},
		/**
		 * セレクトボックスに値をセット
		 * @param {HTMLElement|JQuery} _select DOM
		 * @param {string} _prop セレクト選択値
		 */
		setSelectbox: function setSelectbox(_select, _prop)
		{
			//console.log(_prop);
			const $select = $(_select);
			$select.val(_prop);

			//空値はクラス付加
			if( '' == _prop )
				$select.addClass('placeholder');
			else
				$select.removeClass('placeholder');
			
			const $select_opt = $('option:selected', $select);
			if( $select_opt.prop('disabled') )
			{
				$select.addClass('disabled');
			}
			else
			{
				$select.removeClass('disabled');
			}

			return $select;
		},
	};

	/**
	 * 画面構築関数
	 * @description throttle化 画面書き換え処理フローを書いていく。DOM書換実装はviewモジュールに。
	 */
	const render = _.throttle(
		/**
		 * @param {STAT} _stat イベントから投げられる命令用状態変数
		 */
		(_stat) =>
		{
			//console.title.call('=', 'render' );
			log('命令', _stat);

			/**
			 * 強制レンダリングフラグ
			 */
			const force = _.get(_stat, 'force', false);
			//if( force ) console.title.call('*', '強制レンダリング' );

			/**
			 * 状態変数
			 * @description マージされた新しい変数
			 * @type {STAT}
			 */
			let stat = _.merge( {}, STAT, _stat );

			log('状態', stat);
			/**
			 * 状態差分変数
			 * @type {STAT}
			 */
			let sabun = diff( STAT, stat );

			/**
			 * bind用
			 */
			const bindObj = {
				stat : stat,
				sabun : sabun,
			};

			log('差分', sabun);
			_.merge( STAT, stat, {force:false} ); //アプリケーションスコープ用変数にマージする
			log('現状', STAT);

			/** norenderフラグがあればここで処理終了 */
			if( _.get( _stat, 'norender', false ) )
			{
				_.set( _stat, 'norender', false );
				return stat;
			}

			//――――――――――――――――――――――――――
			//描画コントローラー
			//――――――――――――――――――――――――――

			//タブ切り替え
			if( _.has(sabun,'tab_id') )
			{
				//データをリセット
				_.each( CONFIG.master, item =>{
					if( _.has(item, 'count') ) _.set(item,'count',0);
				} );

				//タブ切り替え命令
				view.updateTab(sabun.tab_id);
			}

			let $tab = null;
			if( _.has(sabun, 'freeword') )
			{
				$tab = $tab || inner.getTargetContentByTabid(stat.tab_id);
				$('[name="freeword"]', $tab).val( nvl( sabun.freeword ) );
			}

			if( _.has(sabun, 'term') )
			{
				$tab = $tab || inner.getTargetContentByTabid(stat.tab_id);
				$('[name="term"]', $tab).val( nvl( sabun.term ) );
			}

			if( _.has(sabun, 'targetDay') )
			{
				$tab = $tab || inner.getTargetContentByTabid(stat.tab_id);
				$('[name="targetDay"]', $tab).val( nvl( sabun.targetDay ) );
			}

			//――――――――――――――――――――――――――
			// 宿泊の出発地情報設定
			//――――――――――――――――――――――――――
			if( _.get(sabun,'tab_id') == CONST.tab.宿泊 )
			{
				const $content = inner.getTargetContentByTabid(CONST.tab.宿泊);

				/** デフォルト表示となる方面名 */
				const homen = _.get(STAT, 'lodge_homen', inner.getDefaultHomenClassname() );
				const treeInfo = inner.searchObject2(CONFIG.hoteldata, homen);

				log('render',homen, treeInfo);

				/** 再レンダリング命令定義 */
				const renderFunc = render.bind(null, {
					tab_id: CONST.tab.宿泊,
					lodge_homen: homen,
					force:true,
				});

				/**
				 * データを取得し直して再レンダリング
				 */
				inner.fetchHomen( CONST.dep.東京, CONST.tab.宿泊, homen )
					.then( renderFunc )
					;

				return stat; //EXIT
			}

			//――――――――――――――――――――――――――
			//国内の出発地情報設定
			//――――――――――――――――――――――――――
			if( _.has(sabun,'jp_deparea') )
			{
				const $content = inner.getTargetContentByTabid(CONST.tab.国内);

				/** 国内用出発地変更関数 */
				const updateDepAreaFunc = view.updateDepArea.bind( $('.whence-radio', $content).eq(0), sabun.jp_deparea );

				/** デフォルト表示となる方面名 */
				const homen = inner.getDefaultHomenClassname();

				/** データセット関数を束縛 */
				const setDataFunc = inner.setData.bind( { tab_id: CONST.tab.国内} );

				const treeInfo = inner.searchObject2(CONFIG.database, homen);
				//console.log(homen, treeInfo);

				/** 再レンダリング命令定義 */
				const renderFunc = render.bind(null, {
					tab_id: CONST.tab.国内,
					jp_homen: homen,
					force:true,
				});

				/**
				 * 国内APIデータをリセット 取得し直し
				 */
				_.chain( CONFIG.master )
					.filter( {disppattern: CONST.api.国内 } )
					.each( (item)=>{
						item.count = 0;
					})
					.value()
					;
				
				/**
				 * データを取得し直して再レンダリング
				 */
				inner.fetchHomen( sabun.jp_deparea, CONST.tab.国内, homen )
					.then( updateDepAreaFunc )
					.then( renderFunc )
					;

				return stat; //EXIT
			}
			//――――――――――――――――――――――――――
			//海外の出発地情報設定
			//――――――――――――――――――――――――――
			else if( _.has(sabun, 'world_deparea') )
			{
				const $content = inner.getTargetContentByTabid(CONST.tab.海外);
				/** 国内と海外で振り分ける */
				const updateDepAreaFunc = view.updateDepArea.bind( $('.whence-radio', $content).eq(0), sabun.world_deparea );

				/** デフォルト表示となる方面名 */
				const homen = inner.getDefaultHomenClassname('world');

				/** データセット関数を束縛 */
				const setDataFunc = inner.setData.bind( { tab_id: CONST.tab.海外} );

				const treeInfo = inner.searchObject2(CONFIG.database, homen);

				/** 再レンダリング命令定義 */
				const renderFunc = render.bind(null, {
					tab_id: CONST.tab.海外,
					world_homen: homen,
					force: true,
				});

				/**
				 * 海外APIデータをリセット
				 */
				_.chain( CONFIG.master )
					.filter( {disppattern: CONST.api.海外 } )
					.each( (item)=>{
						item.count = 0;
					})
					.value()
					;

				/**
				 * データを取得し直して再レンダリング
				 */
				inner.fetchHomen( sabun.world_deparea, CONST.tab.海外, homen )
					.then( updateDepAreaFunc )
					.then( renderFunc )
					;

				return stat;
			}
			//――――――――――――――――――――――――――
			// 出発地の初期値設定 引数_statからセット
			//――――――――――――――――――――――――――
			else if( _.has(_stat,'init_deparea') )
			{
				//――――――――――――――――――――――――――
				//国内・海外両方に適用
				//――――――――――――――――――――――――――
				view.updateDepArea.call( $$('.whence-radio','.form-content') , _stat.init_deparea );
				//取り直し
				view.updateMap.call(bindObj, inner.getDefaultHomenClassname() );
			}

			/**
			 * 方面に変更変数
			 */
			const homen = (()=>
			{
				/**
				 * 戻し用の方面変数
				 * @type {string}
				 */
				let _homen = '';
				let fetchHomenFunc = null;
				const tab_id = _.get(stat, 'tab_id');

				//――――――――――――――――――――――――――
				// 国内：行き先に変更があった場合(差分オブジェを走査)
				//――――――――――――――――――――――――――
				if( tab_id === CONST.tab.国内
					&&( force
					|| _.has( sabun, 'jp_homen' )
					|| _.has( sabun, 'tab_id' )
					))
				{
					_homen = stat.jp_homen = _.get(stat, 'jp_homen' ) || inner.getDefaultHomenClassname();
					fetchHomenFunc = inner.fetchHomen.bind(null, nvl(stat.jp_deparea, stat.init_deparea), CONST.tab.国内, _homen );
				}

				if( tab_id === CONST.tab.宿泊
					&&( force
					|| _.has( sabun, 'lodge_homen' )
					|| _.has( sabun, 'tab_id' )
					))
				{
					_homen = stat.lodge_homen = _.get(stat, 'lodge_homen' ) || inner.getDefaultHomenClassname();
					fetchHomenFunc = inner.fetchHomen.bind(null, 0, CONST.tab.宿泊, _homen );
				}

				//console.warn(stat);
				//――――――――――――――――――――――――――
				// 海外：行き先に変更があった場合(差分オブジェを走査)
				//――――――――――――――――――――――――――
				if( tab_id === CONST.tab.海外
					&& ( force
					|| _.has( sabun, 'world_homen' )
					|| _.has( sabun, 'tab_id' )
					))
				{
					_homen = stat.world_homen = _.get(stat, 'world_homen' ) || inner.getDefaultHomenClassname('world');
					fetchHomenFunc = inner.fetchHomen.bind(null, nvl(stat.world_deparea, stat.init_deparea), CONST.tab.海外, _homen );
				}

				//console.warn(_homen);

				//方面が設定されているのならば、セレクトボックス更新
				if( _homen )
				{
					/**
					 * データを取得し直して再レンダリング
					 */
					fetchHomenFunc()
						.then( ()=>{
							view.updateHomenCombo( tab_id, _homen );
						})
						;
				} 
				return _homen;
			})();

			log('view.updateMap', homen);
			if(homen)
			{
				view.updateMap.call(bindObj, homen );
			}

			//――――――――――――――――――――――――――
			//状態を保存(非同期化実行)
			//――――――――――――――――――――――――――
			// setTimeout(()=>{
			// 	//storage.save( STAT );
			// }, 0);

			return stat;
		}, 400, {leading: true, trailing: true }); // 初回実行時と間引き後の最後に実行される


	/**
	 * イベントアクションコントローラー
	 * *********************************************************
	 * @description 各種イベントを登録
	 */
	const register = _.once(
		/** @param  {STAT} _stat */
		(_stat) =>
		{
			/**
			 * セレクトのdisabledルール追加
			 */
			CSSController.addRule('select option:disabled','color','#D7D7D7');
			CSSController.addRule('select.disabled','color','#CCC');
			CSSController.addRule('select.readonly','background','#F0F0F0');
			CSSController.addRule('select.readonly','cursor','not-allowed');

			//データセットアップ
			inner.mergeDatabase();

			//―――――――――――――――――――――――――――――――――――――――――――――――
			// 初回実行時、出発地に値を定義する
			//―――――――――――――――――――――――――――――――――――――――――――――――
			(() => {
				view.setConstDepArea();
			})();

			//―――――――――――――――――――――――――――――――――――――――――――――――
			// 初回実行時、IPからデフォルト出発地を割り当てる
			//―――――――――――――――――――――――――――――――――――――――――――――――
			(() => {
				/**
				 * 初回実行時、IPからデフォルト出発地を割り当てる用
				 */
				const ip_deparea = inner.getDepAreaByIP();
				if( !_.has( _stat , 'init_deparea') )
				{
					_stat.init_deparea = ip_deparea;
				}
				else
				{
					const tab_id = _.get( _stat, 'tab_id', 1 );
					if( tab_id === 1 )
					{
						_stat.init_deparea = _.get( _stat, 'jp_deparea', _stat.init_deparea);
					}
					else
					{
						_stat.init_deparea = _.get( _stat, 'world_deparea', _stat.init_deparea);
					}
				}

			})();

			//―――――――――――――――――――――――――――――――――――――――――――――――
			// メニュータブをクリックしたときの処理
			//―――――――――――――――――――――――――――――――――――――――――――――――
			(() => {
				const $dom = $$( '.search-for-form .tab-menu li' );
				$dom.on('tap', //_.debounce(
					/**
					 * @param {JQueryEventObject} evt
					 */
					function(evt)
					{
						if (global.is_processing_change_map) return; //EXIT

						/** @type {STAT} */
						const stat = {
							tab_id: +$(this).data('tab-id')
						};

						switch (stat.tab_id) {
							case 1:
								$.eventStop(evt);
								stat.jp_homen = STAT.jp_homen || inner.getDefaultHomenClassname();
								break;
							case 2:
								$.eventStop(evt);
								stat.lodge_homen = STAT.lodge_homen || inner.getDefaultHomenClassname();
								break;
							case 3:
								$.eventStop(evt);
								stat.world_homen = STAT.world_homen || inner.getDefaultHomenClassname('world');
								break;
							default:
								break;
						}
						return render(stat);
					}
				//, 250, false ) //※マップの切替処理が遅すぎるので、多少遅延させてあげないとヤバイ動作になる
				);
			})();
			//―――――――――――――――――――――――――――――――――――――――――――――――
			// 地図タブをクリックしたときの処理
			//―――――――――――――――――――――――――――――――――――――――――――――――
			(() => {
				/**
				 * @param {JQueryEventObject} evt
				 */
				function onMapTabHandler(evt)
				{
					$.eventStop(evt);
					if (global.is_processing_change_map) return ; //EXIT
					const tab_id = $(evt.currentTarget).data('tab-id');
					const isJP = (CONST.tab.国内 === tab_id);
					const isLodge = (CONST.tab.宿泊 === tab_id);
					let stat = {};

					if( isJP )
					{
						stat = {
							tab_id: CONST.tab.国内,
							jp_homen: inner.getDefaultHomenClassname(),
						};
					}
					else if( isLodge )
					{
						stat = {
							tab_id: CONST.tab.宿泊,
							lodge_homen: inner.getDefaultHomenClassname(),
						};
					}
					else
					{
						stat = {
							tab_id: tab_id,
							world_homen: 'world',
						};
					}
					return render(stat);
				}

				const $dom = $$( '.tab-button a' );
				const $tabJapan = $dom.eq(0).data('tab-id',1);
				const $tabWorld = $dom.eq(1).data('tab-id',3);
				$tabJapan.eventFirst('click', onMapTabHandler);
				$tabWorld.eventFirst('click', onMapTabHandler);
			})();

			//―――――――――――――――――――――――――――――――――――――――――――――――
			// 出発地押下時
			//―――――――――――――――――――――――――――――――――――――――――――――――
			(() => {
				//labelにイベントを割り当ててはダメ(inputに割り当てること)
				const $dom = $$('.radio > input', '.whence-radio');
				$dom.eventFirst('click', function(evt)
				{
					const $clicked = $(evt.currentTarget);
					
					/**
					 * 押されたラジオボタン：出発地名
					 */
					const label = $clicked.closest('label').text();
					
					/**
					 * 活性化されているタブIDを取得する
					 */
					const tab_id = inner.getCurrentTabid();

					/**
					 * 描画関数に投げるためのパラメータ
					 * @type {STAT}
					 */
					const stat = {};
					if( CONST.tab.国内 === tab_id )
					{
						/**
						 * 国内の出発地変更値
						 */
						stat.jp_deparea = inner.getDepareaFromLabel( label );
					}
					else if( CONST.tab.海外 === tab_id )
					{
						/**
						 * 海外の出発地変更値
						 */
						stat.world_deparea = inner.getDepareaFromLabel( label );
					}

					//console.warn(tab_id, label, stat);
					return render(stat);
				});
			})();

			/**
			 * 行き先(方面)セレクトボックス選択時の挙動
			 */
			(()=>
			{
				/**
				 * 方面セレクトボックス変更ハンドラー
				 * @param {JQueryEventObject} evt
				 */
				const changeArrHandler = function (evt)
				{
					$.eventStop(evt);
					if (global.is_processing_change_map) return ; //EXIT
					const depth = $(evt.currentTarget).data('depth');
					const tab_id = inner.getCurrentTabid();
					let classname = $(evt.currentTarget).val();

					//console.log(tab_id,classname);

					let stat = {
						norender: false,
					};

					//空白選択された場合
					if( '' === classname )
					{
						let info = null;
						if( STAT.tab_id === CONST.tab.国内 )
							info = inner.searchObject2(CONFIG.database, STAT.jp_homen);
						else if( STAT.tab_id === CONST.tab.宿泊 )
							info = inner.searchObject2(CONFIG.hoteldata, STAT.lodge_homen);
						else if( STAT.tab_id === CONST.tab.海外 )
							info = inner.searchObject2(CONFIG.database, STAT.world_homen);
						classname = _.get(info.sequence, depth-1);

					}
					//console.log('classname', classname);

					if( CONST.tab.国内 === tab_id )
						_.merge(stat, {
							jp_homen: classname,
						});
					else if( CONST.tab.宿泊 === tab_id )
						_.merge(stat, {
							lodge_homen: classname,
						});
					else if( CONST.tab.海外 === tab_id )
						_.merge(stat, {
							world_homen: classname,
						});

					if( STAT.tab_id === CONST.tab.国内 && depth === 2 )
					{
						stat.norender = true;
					}
					else if( STAT.tab_id === CONST.tab.海外 && depth === 3 )
					{
						stat.norender = true;
					}
					else if( STAT.tab_id === CONST.tab.宿泊 && depth === 2 )
					{
						stat.norender = true;
					}

					return render(stat);
				}


				//国内行き先セレクトボックスを初期化
				{
					const $selects = $$('select', '.form-content[data-tab-id="1"]');
					const $select1 = $selects.eq(0);
					const $select2 = $selects.eq(1);
					const $select3 = $selects.eq(2);

					$select1.data('depth',1).on('change', changeArrHandler );
					$select2.data('depth',2).on('change', changeArrHandler );
					$select3.data('depth',3).on('change', changeArrHandler );

					if( !!CONFIG.homen ) $select1.disableSelection();
					if( !!CONFIG.area ) $select2.disableSelection();
					if( !!CONFIG.city ) $select3.disableSelection();
				}

				//国内宿泊行き先セレクトボックスを初期化
				{
					const $selects = $$('select', '.form-content[data-tab-id="2"]');
					const $select1 = $selects.eq(0).attr('name','homen');
					const $select2 = $selects.eq(1).attr('name','area');

					$select1.data('depth',1).on('change', changeArrHandler );
					$select2.data('depth',2).on('change', changeArrHandler );

					if( !!CONFIG.homen ) $select1.disableSelection();
					if( !!CONFIG.area ) $select2.disableSelection();
				}

				//海外行き先セレクトボックスを初期化
				{
					const $selects = $$('select', '.form-content[data-tab-id="3"]');
					const $select1 = $selects.eq(0).attr('name','homen');
					const $select2 = $selects.eq(1).attr('name','area');
					const $select3 = $selects.eq(2).attr('name','city');

					$select1.data('depth',1).on('change', changeArrHandler );
					$select2.data('depth',2).on('change', changeArrHandler );
					$select3.data('depth',3).on('change', changeArrHandler );

					if( !!CONFIG.homen ) $select1.disableSelection();
					if( !!CONFIG.area ) $select2.disableSelection();
					if( !!CONFIG.city ) $select3.disableSelection();
				}
			})();

			//―――――――――――――――――――――――――――――――――――――――――――――――
			// マップ上のボタンをクリックしたときの処理
			//―――――――――――――――――――――――――――――――――――――――――――――――
			(() => {
				const $dom = $$( 'a', '.search-map li' );
				$dom.eventFirst('click',evt =>
				{
					const $li = $(evt.currentTarget).closest('li');
					const classname = _($li.get(0).className).split(' ').without('link').without('back').first() || '';
					const masterInfo = _.find(CONFIG.master, {
						classname: classname
					});

					//console.log(masterInfo);

					if( $li.hasClass('link') )
					{
						const $alink = $('a', $li);
						const paramObj = {
							listDisp : inner.getModeByTabId( STAT.tab_id ),
							searchPattern : inner.getSearchPatternByDispPattern( masterInfo.disppattern ),
							depArea: _.get(masterInfo, 'deparea', ''),
							homen: _.get(masterInfo, 'homengcd', ''),
							area: _.get(masterInfo, 'homencd', ''),
							city: _.get(masterInfo, 'city', ''),
						};

						if( STAT.tab_id === CONST.tab.宿泊 )
							_.merge( paramObj, { searchTab: 'S03' } );

						const param = $.param(paramObj);

						const href = `${CONFIG.url.search}?${param}`;
						$alink.attr('href', href);
						$alink.attr('target', masterInfo.classname || '_blank');
						return true;
					}

					$.eventStop(evt); //イベント伝播停止
					const info = inner.searchObject2(CONFIG.database, classname);
					const isJP = _.first( info.sequence ) == 'japan';
					const isLodge = (CONST.tab.宿泊 === STAT.tab_id);

					let stat = {};
					if( isLodge )
						stat = {
							lodge_homen: classname,
						};
					else if( isJP )
						stat = {
							jp_homen: classname,
						};
					else
						stat = {
							world_homen: classname,
						};

					return render(stat);
				});

			})();

			//―――――――――――――――――――――――――――――――――――――――――――――――
			// フリーワードや送信関連
			//―――――――――――――――――――――――――――――――――――――――――――――――
			(() => {
				/**
				 * 地図にあるAタグに設定されているデータを取得する
				 * @param {string} _classname クラス名
				 * @param {string} _key 取得したいキー名
				 */
				function getData(_classname, _key)
				{
					//console.log(_classname,_key);
					if( !_classname || !_key ) return '';

					const masterInfo = _.find(CONFIG.master, {
						classname: _classname
					});

					return _.get(masterInfo, _key, '');
				}

				$('form.search-form').each(
				/**
				 * フォーム検索機能
				 * @param {HTMLFormElement} _form
				 */
				(_idx, _form)=>
				{
					//フリーワードが入力されている場合、方面必須チェックを外す
					$('input[name="freeword"]', _form)
						.on('change', evt => {
							const $f_free = $(evt.currentTarget);
							const required = nvl($f_free.val()) == '';
							const $f_homen = $('select[name="homen"]', _form);
							const $f_area = $('select[name="area"]', _form);
							$f_homen.prop('required', required);
							$f_area.prop('required', required);
						})
						.trigger('change')
						;


					$(_form)
						.attr('action', CONFIG.url.search)
						.attr('method', 'GET')
						.submit(evt =>
						{
							$.eventStop(evt);
							/** @type {HTMLFormElement} */
							const form = evt.currentTarget;

							const $f_dep = $('input[name="depArea"]:checked', form);
							const $f_homen = $('select[name="homen"]', form);
							const $f_area = $('select[name="area"]', form);
							const $f_city = $('select[name="city"]', form);
							const $f_date = $('input[name="targetDay"]', form);
							const $f_term = $('select[name="term"]', form);
							const $f_free = $('input[name="freeword"]', form);
							const $form_send = $('.submit', form);

							/**
							 * 情報を集めて送信
							 * list?depArea=2&homen=C&area=C1&city=C10&searchPattern=1
							 * @type {SearchParam}
							 */
							const param = {
								listDisp : inner.getModeByTabId( STAT.tab_id ),
								soat: 0, //0が料金順??
								searchPattern : inner.getSearchPatternByTabId( STAT.tab_id ),
								depArea: $f_dep.val(),
								homen: getData($f_homen.val(), 'homengcd'),
								area: getData($f_area.val(), 'homencd'),
								city: getData($f_city.val(), 'city'),
								targetDay: nvl( $f_date.val() ),
								term: nvl( $f_term.val() ),
								freeword: nvl( $f_free.val() ),
								nextPage : 0,
							};

							if( STAT.tab_id === CONST.tab.宿泊 )
								_.merge( param, { searchTab: 'S03' } );

							// console.info(param);
							inner.gotoSearch( param );
							return false;
						})
						;
				});
			})();

			/**
			 * タブの状態が取得できないならば設定
			 */
			if( !_.has( _stat, 'tab_id' ) )
			{
				_stat.tab_id = $('.form-content.current').data('tab-id');
			}

			/**
			 * 初回表示用地名
			 */
			if( _stat.tab_id === CONST.tab.国内 && !_.has( _stat, 'jp_homen' ) )
			{
				_stat.jp_homen = inner.getDefaultHomenClassname();
			}

			if( _stat.tab_id === CONST.tab.宿泊 && !_.has( _stat, 'lodge_homen' ) )
			{
				_stat.lodge_homen = inner.getDefaultHomenClassname();
			}

			if( _stat.tab_id === CONST.tab.海外 && !_.has( _stat, 'world_homen' ) )
			{
				_stat.world_homen = inner.getDefaultHomenClassname('world');
			}

			//console.log(_stat);
			return _stat;
		});

	const start = function start()
	{
		const tab_id = +$('.form-content').eq(0).data('tab-id') || 1;
		return Promise.resolve({
			tab_id: tab_id
		});
	};

	//-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
	// モジュール公開
	//-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
	return {
		start : start,
		storage : storage,
		render : render,
		view: view,
		inner: inner,
		register : register,
		STAT: STAT,
	}
})( getGlobal() ); //jsのglobalオブジェクトを取得する技


/**
 * メイン処理開始
 */
jQuery(document).ready(($)=>
{

	Ctrl.start()
	.then( Ctrl.register )
	.then(
		/** @param {STAT} _stat */
		( _stat )=>
		{
			//初回表示方面が設定されている場合
			const deparea = nvl(_.get( _stat, 'init_deparea' ) , CONST.dep.九州 );
			const tab_id = _.get( _stat, 'tab_id' ) || 1;

			const classname = Ctrl.inner.getDefaultHomenClassname('');
			const info = Ctrl.inner.searchObject2(CONFIG.database, classname);

			//_statに命令追記
			if( tab_id == CONST.tab.国内 )
				_.merge({
					jp_homen : classname,
				}, _stat);
			else if( tab_id == CONST.tab.宿泊 )
				_.merge({
					lodge_homen : classname,
				}, _stat);
			else if( tab_id == CONST.tab.海外 )
				_.merge( {
					world_homen : classname,
				}, _stat);

			//フェッチングして命令をrenderに渡す
			return Ctrl.inner.fetchHomen( deparea, tab_id, classname )
							.then(function(){
								//console.info(_stat);
								return Promise.resolve(_stat);
							});
		}
	)
	.then( Ctrl.render )
	.then( function( _stat )
	{
		//console.info('Ctrl.ready');
		//Ctrl準備完了イベント発火
		$( getGlobal() ).trigger( new $.Event('Ctrl.ready') );
	})
	;
});

(function(){
	//ランクング描画
	const ranking = function ranking()
	{
		return new Promise( function(_resolve, _reject)
		{
			const $block = $('.ranking').parent();
			//ランキングエリアがなければなにもしない
			if( $block.length === 0 ) return _resolve();

			const HOMEN = CONFIG.city || CONFIG.area || CONFIG.homen;
			const rank_homen_cd = CONFIG.rank_homen_cd;
			const masterInfo = _.find( CONFIG.master, {classname: HOMEN} );

			$.Deferred().resolve(0)
				.then(function()
				{
					//方面設定チェック
					if( !masterInfo ) return $.Deferred().reject(); //EXIT 方面設定がなければexit
				})
				.then( Ajax.defer({
					url : CONFIG.url.ranking,
					type : 'GET',
					dataType: 'jsonp',
					jsonpCallback : 'callback',
					crossDomain: true,
					cache: false,
				}) )
				.then(function(_json){
					//ランキングデータ選別
					const item = _.find(_json, {homeng: rank_homen_cd || masterInfo.code});
					//console.log(item);
					
					if( _.has( item,'ranking') && _.size( item.ranking ) > 0 )
						return item.ranking;

					return $.Deferred().reject();
				})
				.then( function(_ranks)
				{
					//事前処理
					$('.block-inner li', $block)
						.each(function(idx, dom)
						{
							const rankItem = _.find(_ranks, { ranking: idx + 1 });

							if( rankItem )
							{
								const detailurl = `${CONFIG.url.detail_pc}${rankItem.shohinCd}`;
								const alttext = `${rankItem.image}`.split('/').pop();
								const $html = $(`
									<a href="${detailurl}">
										<img src="${rankItem.image}" alt="${alttext}" class="item-image">
										<div>
											<p class="tag">${rankItem.homenName}</p>
											<p>${rankItem.name}</p>
										</div>
									</a>
								`);

								$('img.item-image', $html )
									.on('error', function(evt)
									{
										//console.info(evt);
										//404取得できない場合の処理
										$(this).parent().prepend( $(`<span class="noimage">NO IMAGE</span>`) );
										$(this).remove();
									});
								$(dom).html($html).show();
							}
							else
							{
								const html = ``;
								$(dom).hide();
							}
						});
				})
				.fail(function(err){
					$block.hide(0);
				})
				.always(function(){
					return _resolve();
				})
				;
		});
	};

	$( getGlobal() ).on('Ctrl.ready',function(evt)
	{
		ranking();
	})
})();
